import React from 'react';
import NewItem from "./NewItem";
import Pagination from "../Pagination/Pagination";
import "./main.css"
import {useDispatch, useSelector} from "react-redux";
import {getNewsSelected} from "../../redux/selector/app-selector";
import {getNewsThunk} from "../../redux/app-reducer";
import {useTranslation} from "react-i18next";

const NewsContainer = () => {
    const {t} = useTranslation()
    const url_img=process.env.REACT_APP_API_URL_IMG;
    const news_state = useSelector(getNewsSelected);
    const dispatch = useDispatch();
    const handlerChangePage = (page) => {
        console.log(page);
        dispatch(getNewsThunk(page));
    };
    if(!news_state.page) return null
    return (
        <section className={"section-news"}>
            <div className={'container'}>
                <div className="row">
                    <h5 className="mb-2">{t("news_title")}</h5>
                    <div className="news-wrap">
                        {news_state.news.sort((a,b)=>{
                            return new Date(b.updated_at) - new Date(a.updated_at);
                        }).map(new_item => <NewItem item={new_item} url_img={url_img}/>)}
                    </div>
                    <Pagination count={news_state.pages} changeHandler={handlerChangePage}/>
                </div>
            </div>
        </section>
    );
};

export default NewsContainer;